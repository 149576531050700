import { ButtonProps } from 'interfaces/index';
import { Icon } from '@iconify/react';
import { match } from 'utils/match';

const Button: React.FC<ButtonProps> = ({
  children,
  isLoading = false,
  loadingText = 'Loading...',
  size = 'medium',
  onClick,
  icon,
  id,
  type = 'button',
  className,
  isDisabled = false,
  color = '',
}) => {
  const sizeType = match(size, {
    small: 'px-2 py-2 text-[12px]',
    medium: 'px-6 py-3 text-[16px]',
    large: 'px-8 py-4 text-[18px]',
  });
  //old implementation - not advisable to implement nested ternary operators given that cases may grow in future
  //  const size === 'small'
  //     ? 'px-2 py-2 text-[12px]'
  //     : size === 'medium'
  //     ? 'px-6 py-3 text-[16px]'
  //     : size === 'large'
  //     ? 'px-8 py-4 text-[18px]'
  //     : null;

  return (
    <button
      id={id}
      disabled={isDisabled}
      onClick={onClick}
      type={type}
      className={`${className} ${sizeType} ${
        isDisabled
          ? 'cursor-not-allowed border-slate-200 text-white opacity-80'
          : ''
      } flex items-center justify-center gap-2 rounded-md ${
        isLoading ? 'bg-primary-500/80' : ' bg-primary-500'
      }`}
      style={{
        background: color,
      }}
    >
      {isLoading && (
        <span aria-hidden="true" className={``}>
          <Icon className="animate-spin" icon="codicon:loading" />
        </span>
      )}
      {!isLoading && icon && (
        <span aria-hidden="true" className={``}>
          <Icon icon={icon} />
        </span>
      )}
      <span>{isLoading ? loadingText : children}</span>
    </button>
  );
};

export default Button;
