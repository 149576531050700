import Image from 'next/image';
import Link from 'next/link';
import {
  mobileFooterLinksData,
  paymentMethods,
  footerLinksData,
} from 'utils/landing-page/footerLinksData';

const Footer = () => {
  return (
    <footer className="bg-primary-600 py-8 text-white lg:py-10">
      <div className="mx-auto w-full lg:w-11/12">
        <div className="grid grid-cols-3 gap-2 px-4 lg:hidden">
          {mobileFooterLinksData.map((mobileLink, index) => (
            <div key={index} className="flex flex-col gap-1">
              <h4 className="text-sm font-normal uppercase">
                {mobileLink.linksHeader}
              </h4>
              <ul className="flex flex-col">
                {mobileLink.links.map((link, index) => (
                  <li key={index}>
                    <Link href={link.url}>
                      <a className="text-[10px] font-light leading-4 hover:underline">
                        {link.name}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div>
            <h4 className="font-normal uppercase">Contact Info</h4>
            <address className="text-[10px] not-italic leading-4">
              20A, Babatunde Anjous Avenue off Admiralty Way, Lekki Phase 1,
              Lagos, Nigeria. T: +234 908 7516 846
            </address>
          </div>
          <div>
            <h4 className="mb-3 text-sm font-normal uppercase">
              Payment Methods
            </h4>
            <ul className="flex flex-wrap gap-3">
              {paymentMethods.map((paymentMethod, index) => (
                <li key={index}>
                  <Image
                    className="rounded-lg border-2"
                    src={paymentMethod.src}
                    width={27}
                    height={27}
                    alt=""
                    objectFit="cover"
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="hidden justify-between gap-x-8 gap-y-8 px-5 py-14 lg:grid lg:grid-cols-5 lg:gap-y-4 lg:px-6">
          {footerLinksData.map((footerLink, index) => (
            <div key={index} className="flex flex-col gap-4">
              <h4 className="text-sm font-semibold uppercase lg:text-base">
                {footerLink.linksHeader}
              </h4>
              <ul className="flex flex-col gap-1 lg:gap-2">
                {footerLink.links.map((link, index) => (
                  <li key={index}>
                    <Link href={link.url}>
                      <a className="text-[14px] font-light leading-[1.25rem] hover:underline lg:text-[13px]">
                        {link.name}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
              {index === footerLinksData.length - 1 && (
                <div>
                  <h4 className="mb-3 mt-16 text-lg font-semibold uppercase">
                    Payment Methods
                  </h4>
                  <ul className="flex gap-4">
                    {paymentMethods.map((paymentMethod, index) => (
                      <li key={index}>
                        <Image
                          className="rounded-lg border-2"
                          src={paymentMethod.src}
                          width={45}
                          alt=""
                          height={45}
                          objectFit="cover"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="hidden bg-[#047404] py-6 lg:block">
        <address className="text-center font-light not-italic">
          <span className="font-medium">CONTACT US</span>: 20A, Babatunde Anjous
          Avenue off Admiralty Way, Lekki Phase 1, Lagos, Nigeria. | T:{' '}
          <a className="hover:underline" href="tel:+234 908 7516 846">
            +234 908 7516 846
          </a>{' '}
          | E:{' '}
          <a className="hover:underline" href="mailto:info@bitMARTe.com">
            info@bitMARTe.com
          </a>
        </address>
      </div>
      <div className="my-5 hidden text-center text-xs font-semibold lg:mt-10 lg:block  lg:text-sm">
        © 2022 bitMARTe C-BIT INDUSTRIES LIMITED
      </div>
      <div className="my-5 block text-center text-xs font-semibold lg:mt-10 lg:hidden  lg:text-sm">
        © 2022 bitMARTe LIMITED
      </div>
    </footer>
  );
};

export default Footer;
