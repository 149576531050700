import Button from 'components/shared/Button';
import { Icon } from '@iconify/react';
import Link from 'next/link';
import { useState } from 'react';
import { landingPageNavLinks } from 'utils/landing-page/landingPageNavLinks';
import { useRouter } from 'next/router';

const MobileNav = () => {
  const [mobileNav, setMobileNav] = useState(false);

  const router = useRouter();

  return (
    <div className="relative flex-1 lg:hidden">
      <div className="flex w-full items-center justify-between bg-white pl-2">
        <div className="">
          <Link href="/">
            <a className="text-2xl font-bold uppercase">
              <img
                src="/assets/img/bitMARTe-logo.png"
                alt="bitMARTe logo"
                className="h-[70px]"
              />
            </a>
          </Link>
        </div>
        <Button color="transparent" onClick={() => setMobileNav(!mobileNav)}>
          <Icon icon="bx:menu" className="text-3xl" />
        </Button>
      </div>
      {mobileNav && (
        <div className="mobile-nav absolute top-[70px] right-0 h-auto w-[196px] space-y-10 bg-white px-8 pt-4 pb-8">
          <ul className="flex flex-col items-start gap-3">
            {landingPageNavLinks.map(({ link, name }, index) => (
              <li key={index}>
                <Link href={link}>
                  <a
                    className={`${
                      router.asPath === `${link}` &&
                      'bg-transparent text-primary-500'
                    } font-medium capitalize text-black transition-all hover:text-primary-500 focus:text-primary-500 active:text-primary-500`}
                  >
                    {name}
                  </a>
                </Link>
              </li>
            ))}
            <li className="mt-8 self-center">
              <Link href="/merchant/signup">
                <a className="rounded-md border border-primary-500 bg-primary-500 py-2 px-4 text-sm font-medium text-white transition-all hover:bg-transparent hover:text-primary-500">
                  Get Started
                </a>
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileNav;
