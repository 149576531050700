export const landingPageNavLinks = [
  {
    name: 'home',
    link: '/',
  },
  {
    name: 'features',
    link: '/coming-soon',
  },
  {
    name: 'pricing',
    link: '/pricing',
  },
  {
    name: 'faq',
    link: '/coming-soon',
  },
  {
    name: 'blog',
    link: '/coming-soon',
  },
  {
    name: 'help',
    link: '/coming-soon',
  },
  {
    name: 'log in',
    link: '/merchant/login',
  },
];
