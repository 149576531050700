export const footerLinksData = [
  {
    linksHeader: 'About Us',
    links: [
      {
        url: '/coming-soon',
        name: 'bitMARTe People',
      },
      {
        url: '/coming-soon',
        name: 'Payment Methods',
      },
      {
        url: '/coming-soon',
        name: 'Payment Partners',
      },
      {
        url: '/coming-soon',
        name: 'Delivery Partners',
      },
      {
        url: '/coming-soon',
        name: 'Our Delivery Promise',
      },
      {
        url: '/coming-soon',
        name: 'Blog',
      },
      {
        url: '/coming-soon',
        name: 'Newsletter',
      },
      {
        url: '/coming-soon',
        name: 'Press Center',
      },
    ],
  },
  {
    linksHeader: 'Sell on bitmarte',
    links: [
      {
        url: '/coming-soon',
        name: 'Be a bitMARTe Merchant',
      },
      {
        url: '/coming-soon',
        name: 'Why you Should sell on bitMARTe',
      },
    ],
  },
  {
    linksHeader: 'Resources',
    links: [
      {
        url: '/coming-soon',
        name: 'Terms and Conditions',
      },
      {
        url: '/coming-soon',
        name: 'Privacy Policy',
      },
      {
        url: '/coming-soon',
        name: 'Dispute Resolution Policy',
      },
      {
        url: '/coming-soon',
        name: 'Warranty/ Guarantee',
      },
    ],
  },
  {
    linksHeader: 'Here to help you',
    links: [
      {
        url: '/coming-soon',
        name: 'User Guide',
      },
      {
        url: '/coming-soon',
        name: 'Your Needs Delivered',
      },
      {
        url: '/coming-soon',
        name: 'Your Account',
      },
      {
        url: '/coming-soon',
        name: 'Your Orders',
      },
      {
        url: '/coming-soon',
        name: 'Delivery of Your Order',
      },
      {
        url: '/coming-soon',
        name: 'General Shipping Information',
      },
      {
        url: '/coming-soon',
        name: 'Returns & Replacements',
      },
      {
        url: '/coming-soon',
        name: 'FAQ',
      },
      {
        url: '/coming-soon',
        name: 'Contact Us',
      },
      {
        url: '/coming-soon',
        name: 'Make a Report',
      },
    ],
  },
  {
    linksHeader: 'Partner with us',
    links: [
      {
        url: '/coming-soon',
        name: 'Be a bitMARTe Business Champion',
      },
      {
        url: '/coming-soon',
        name: 'Be a bitMARTe Brand Ambassador ',
      },
      {
        url: '/coming-soon',
        name: 'Be a bitMARTe Service Provider',
      },
      {
        url: '/coming-soon',
        name: 'Be a bitMARTe  Logistics Partner',
      },
      {
        url: '/coming-soon',
        name: 'Be a bitMARTe Content Provider',
      },
    ],
  },
];
export const mobileFooterLinksData = [
  {
    linksHeader: 'MY ACCOUNT',
    links: [
      {
        url: '/coming-soon',
        name: 'Logout',
      },
      {
        url: '/coming-soon',
        name: 'Order History',
      },
      {
        url: '/coming-soon',
        name: 'My Wishlist',
      },
      {
        url: '/coming-soon',
        name: 'Track Order',
      },
    ],
  },
  {
    linksHeader: 'Seller Info',
    links: [
      {
        url: '/coming-soon',
        name: 'Sell on BitMARTe',
      },
      {
        url: '/coming-soon',
        name: 'Be a bitMARTe Merchant',
      },
      {
        url: '/coming-soon',
        name: 'Seller Policy',
      },
    ],
  },
  {
    linksHeader: 'Legal Info',
    links: [
      {
        url: '/coming-soon',
        name: 'Returns Policy',
      },
      {
        url: '/coming-soon',
        name: 'Bitmarte Privacy Notice',
      },
      {
        url: '/coming-soon',
        name: 'Terms & conditions',
      },
    ],
  },
  {
    linksHeader: 'Support',
    links: [
      {
        url: '/coming-soon',
        name: 'Help Center',
      },
      {
        url: '/coming-soon',
        name: 'Track your Order',
      },
      {
        url: '/coming-soon',
        name: 'Track your Order',
      },
    ],
  },
];
export const paymentMethods = [
  {
    src: '/assets/img/mastercard.png',
  },
  {
    src: '/assets/img/apple-pay.png',
  },
  {
    src: '/assets/img/stripe.png',
  },
  {
    src: '/assets/img/visa.png',
  },
  {
    src: '/assets/img/paypal.png',
  },
];
