import Footer from 'components/landingPage/Footer';
import Nav from 'components/landingPage/Nav';

const LandingPageLayout: React.FC = ({ children }) => {
  return (
    <section className="landing flex min-h-screen flex-col justify-between">
      <Nav />
      {/* margin top 70px which is equal to the height of the navbar */}
      <section className="landing-content mt-[70px]">{children}</section>
      <Footer />
    </section>
  );
};

export default LandingPageLayout;
