import Link from 'next/link';
import { useRouter } from 'next/router';
import { landingPageNavLinks } from 'utils/landing-page/landingPageNavLinks';
import MobileNav from './MobileNav';

const Nav = () => {
  const router = useRouter();

  return (
    <nav className="fixed z-50 flex h-[70px] w-full items-center bg-white shadow">
      <MobileNav />
      <div className="mx-auto hidden w-10/12 items-center justify-between lg:flex">
        <div className="w-44">
          <Link href="/">
            <img
              src="/assets/icons/bitMARTe-logo 3.svg"
              alt="bitMARTe logo"
              className="w-full"
            />
          </Link>
        </div>
        <ul className="flex items-center justify-between gap-12">
          {landingPageNavLinks.map(({ link, name }, index) => (
            <li key={index}>
              <Link href={link}>
                <a
                  className={`${
                    (router.asPath === link ||
                      router.pathname.startsWith(link)) &&
                    'bg-transparent font-semibold text-primary-500'
                  }font-medium capitalize text-black transition-all hover:text-primary-500 focus:text-primary-500 active:text-primary-500 md:text-sm 2xl:text-base`}
                >
                  {name}
                </a>
              </Link>
            </li>
          ))}
          <li>
            <Link href="/merchant/signup">
              <a className="rounded-md border border-transparent bg-primary-500 py-2 px-4 font-medium text-white transition-all hover:bg-primary-400 md:text-sm 2xl:text-base">
                Get Started
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
